<template>
  <div class="container index">
    <no-ssr>
      <div v-swiper:mySwiper="swiperOptions" class="swiper">
        <div class="swiper-wrapper">
          <div v-for="index in 12" :key="index" class="swiper-slide">
            <img :src="'/img/carousel/' + index + '.jpg'" />
          </div>
        </div>
      </div>
    </no-ssr>
    <div class="text">
      <div class="content text-center">
        <img class="logo" src="/img/logo.png" />
        <div class="hero-contact text-lg pt-8">
          <p class="inline w-full">Ilije Garašanina 20</p>
          <br />
          <a class="w-full" href="tel:+381642420733">+381642420733</a>
          <br />
          <a class="w-full" href="mailto:office@folicarchitects.rs"
            >office@folicarchitects.rs</a
          >
        </div>
      </div>
    </div>
    <div class="swiper-pagination-index"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination-index',
          clickable: true
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        }
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.index .swiper-slide {
  height: 100vh !important;
  width: 100vw;
}
.index .swiper-slide img {
  height: 100vh !important;
  max-height: unset !important;
  width: 100vw;
  object-fit: cover;
}

.index .swiper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh !important;
  left: 0;
  z-index: -1;
}

.index .swiper-pagination {
  z-index: 50;
}

.text {
  display: flex;
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  justify-content: center;
  z-index: -1;
  height: calc(100vh-70px);
  width: 100vw;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.33);
}
.content {
  padding: 20px;
  z-index: 40;
}
h2 {
  /* font-family: 'Poppins', sans-serif; */
  color: #26abff;
  text-align: center;
}
.logo {
  height: 160px;
  @screen sm {
    height: 240px;
  }
  width: auto;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8);
}

.hero-contact a,
.hero-contact p {
  margin: 10px;
  width: auto;
  padding: 2px 5px;
  background: rgba(255, 255, 255, 0.6);
}
.hero-contact a:hover {
  color: #ffbf00;
  transition: 0.4s;
}
.swiper-pagination-index {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.swiper-pagination-index .swiper-pagination-bullet {
  height: 14px !important;
  width: 14px !important;
  /* border-radius: 0 !important; */
  /* outline: 5px solid yellow; */
}

.swiper-pagination-bullet-active {
  background: white;
}
</style>
